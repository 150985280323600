<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <AppLogo />
        <!-- <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Mot de passe oublié? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Entrer votre adresse email et nous vous enverrons des instructions pour réinitialiser votre mot de passe.
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <b-form-input
                id="forgot-password-email"
                v-model="userEmail"
                :state="$v.userEmail.$error ? false : null"
                name="forgot-password-email"
                placeholder="john@example.com"
              />
              <div v-if="$v.userEmail.$error">
                <small
                  v-if="$v.userEmail.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
                <small
                  v-if="$v.userEmail.email.$invalid"
                  class="text-danger"
                >Adresse email invalid</small>
              </div>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              @click="forgetPassword"
            >
              Envoyer le lien de réinitialisation
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <b-link href="/login">
              <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import router from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import AppLogo from '@/components/AppLogo.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    AppLogo,
    ToastificationContent,
  },
  setup: () => ({ $v: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      userEmail: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  validations() {
    return {
      userEmail: { required, email },
    }
  },
  methods: {
    ...mapActions('auth', {
      action_forgetPassword: 'forgetPassword',
    }),
    async validationForm() {
      if (this.isLoading) return
      const isFormCorrect = await this.$v.$validate()
      if (!isFormCorrect) return
    },
    forgetPassword() {
      const params = { email: this.userEmail }
      this.action_forgetPassword(params)
        .then(res => {
          // console.log('response when forget-password: ', res)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lien envoyé. Veuillez consulter votre adresse mail',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (res) {
            this.$router.push({ name: 'auth-code', query: { email: this.userEmail } })
          }
        })
        .catch(err => {
          // console.log('err', err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Vous n\'avez pas de compte: vous devez d\'abord vous inscrire',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
